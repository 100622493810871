var _URL = window.URL || window.webkitURL;
var _validFileExtensions = [".jpg", ".jpeg"]; 

function showSomeone(popup) {
    $("#" + popup).show();
}
function closeSomeone(popup) {
    $("#" + popup).hide();
}
function closeModal(popup){
    $("#" + popup).modal('toggle'); 
}
function changeTo(popupCls, popupOpn){
    //Cierra
    $("#" + popupCls).hide();
    //Abre
    $("#" + popupOpn).show();
}
function validateNumber(evt) {
  var theEvent = evt || window.event;
  var key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode( key );
  var regex = /[0-9]/;
  if( !regex.test(key) ) {
    theEvent.returnValue = false;
    if(theEvent.preventDefault) theEvent.preventDefault();
  }
}
function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
});

function PreviewImage(src,display,oInput) {
        
    if (!document.getElementById(src).files[0].name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
        alert('Favor de ingresar una imagen');
    } else {
        var file = getSelectedFile(src);
        isGoodImage(file).then(function(isGood) {
            if (isGood){
                var size = document.getElementById(src).files[0].size;
                if(size < 7340032){
                    var oFReader = new FileReader();
                    oFReader.readAsDataURL(document.getElementById(src).files[0]);

                    if(src == "archivoOtro"){
                        //Si es otra foto genera otro recuadro
                        var number = 0;
                        $('[id^=preOtro_]').each(function() {
                            number = this.id.split('_').pop();
                        });
                        number++;
                        var strHTML ="<a href='#' class='anadir w-clearfix w-inline-block' id='preOtro_"+number+"'> "+
                                        "   <div class='quitar' onclick='quitaFoto("+number+")'> "+
                                        "       <div class='tache'>X</div> "+
                                        "   </div> "+
                                        "   <input type='hidden' id='archivoOtro_"+number+"' value='"+document.getElementById(src).files[0].name.toLowerCase()+"' />"+
                                        "</a>";
                        document.getElementById("spnFotoAdicional").innerHTML = document.getElementById("spnFotoAdicional").innerHTML + strHTML;
                        oFReader.onload = function (oFREvent) {
                            document.getElementById("preOtro_"+number).style.backgroundImage = "url('"+oFREvent.target.result+"')";
                            document.getElementById("preOtro_"+number).style.backgroundSize = "cover";
                            document.getElementById("preOtro_"+number).style.backgroundPosition = "center";
                        };
                    } else {
                        oFReader.onload = function (oFREvent) {
                            document.getElementById(display).style.backgroundImage = "url('"+oFREvent.target.result+"')";
                            document.getElementById(display).style.backgroundSize = "cover";
                            document.getElementById(display).style.backgroundPosition = "center";
                        };
                    }
                } else {
                    alert('Imagen muy grande, selecciona una imagen menor a 7MB');
                }
            } else {
                oInput.value = "";
                return false;
            }
        });
    }   
};

function fotoClick(archivo){
    var browseField = document.getElementById(archivo);
    browseField.click();
}

function getSelectedFile(src) {
    var fileInput = document.getElementById(src);
    var fileIsSelected = fileInput && fileInput.files && fileInput.files[0];
    if (fileIsSelected)
        return fileInput.files[0];
    else
        return false;
}

function isGoodImage(file) {
    var deferred = jQuery.Deferred();
    var image = new Image();

    image.onload = function() {
        // Check if image is bad/invalid
        if (this.width + this.height === 0) {
            this.onerror();
            return;
        }
        // Check the image resolution
        if (this.width >= 399 && this.height >= 150) {
            deferred.resolve(true);
        } else {
            alert("La resolucion de la imagen es muy pequeña, se recomienda una resolucion mínima de 399x150 pixels o mayor.");
            deferred.resolve(false);
        }
    };

    image.onerror = function() {
        alert("Imagen invalida, favor de seleccionar otra.");
        deferred.resolve(false);
    }

    image.src = _URL.createObjectURL(file);

    return deferred.promise();
}

function quitaFoto(valor){
    if(valor){
        document.getElementById("preOtro_"+valor).remove();
    }
}

function quitaFile(element,div){
    if(element){
        document.getElementById(element).value = "";
        document.getElementById(div).style.backgroundImage = '';
        document.getElementById(div).style.background = '';
        document.getElementById(div).style.backgroundUrl = '';
    }
}